import React from 'react';
import { Link } from 'react-router-dom';

function Strip(props) {
  return (
    <div className='strip container-fluid home' style={{ backgroundImage: "url(/images/cover_home2_light.jpg)" }}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6" >
            <div>
              <h1 className='text-md-end text-center mt-5 mt-md-0 h2'>
             המנוי שיכין אותך למקצועות
             <br/>
              בעולם ההיי טק
                </h1>
              <p className='my-4 mx-auto col-9 col-md-12 h5 d-none d-md-block'>
              MONKEYS PREMIUM - מסלול לימודים מסודר שיבנה אותך, החל מהיסודות של HTML , JS ועד לפרויקטים מתקדמים ב NODEJS, REACT ועוד..., ויהפוך אותך למפתח פול סטאק מקצועי.
              </p>
              {/* <ul >
                <li className='h5'>מעל 500 סרטוני הדרכה מפורטים</li>
                <li className='h5'>יותר מ-70 שעות של תוכן לימודי ומעשי</li>
                <li className='h5'>שיטה ייחודית וקלה ללימודי פולסטאק</li>
                <li className='h5'>קבצי קוד </li>
              </ul> */}
              <div className='text-center text-md-end'>
                <Link to="/courses_vod"  className='btn-start btn px-4 py-2 mb-3 h4 mt-3' style={{color:"white",fontWeight:"bold"}}>לרשימת הקורסים המלאה</Link>
                
              </div>
            </div>
          </div>
          <div className="col-md-6 text-dark">
            <div className="bg-white rounded col-11 me-auto  my-card mx-auto  mx-md-0 me-md-auto my-4 my-md-0" >
              <div className='bg-card rounded' style={{ backgroundImage: "url(/images/cover_home2.jpg)" }}></div>
              <div className="card-body">
                <h4 className="card-title text-end">מנוי פרימיום - לכל סרטוני האתר</h4>
                <p className="card-text">
                הרשם וקבל גישה ל MONKEYS PREMIUM מנוי הכולל את כל חבילות הקורסים של מאנקיס במקום אחד, עם עדכונים של סרטונים חדשים</p>
                <a href="/premium" style={{color:"black"}}>לרכישת מנוי לחץ כאן</a>

              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Strip